<template>
  <div>

    <div class="row">
      <div class="col-3"></div>
      <div class="col-6">
        <h1 style="text-align: center">{{ substation_data.name }}</h1>
      </div>
      <div class="col-3">
        <div>
          <span style="float:left">Page Last Refreshed:</span>
          <span style="float:right">{{datetime_refresh_data | formatDate}}</span>
        </div>
        <div>
          <span style="float:left">Displayed Data Timestamp:</span>
          <span style="float:right">{{datetime_data | formatDate}}</span>
        </div>
        <div>
          <span style="float:left">Next Refresh In: {{remaining_time}}s</span>
          <base-button size="sm" type="primary" style="float:right" @click="getSensorData">Refresh</base-button>
        </div>
      </div>
    </div>
    
    <div class="row text-white">
      <div class="col">
        <card class="border">
          <h4 style="text-align: center">Junctions</h4>
          <card class="border">
            <h4>J01</h4>
            <div v-for="item in sensor_mapping_data" :key="item.id">
              <div v-if="item.sensor_junction == 'J01'">
                <span>
                  <span v-if="item.indicator_type == 'RG'">
                    <span v-if="item.sensor_value == 0" class="badge badge-pill badge-danger text-danger">.</span>
                    <span v-else-if="item.sensor_value == 1" class="badge badge-pill badge-success text-success">.</span>
                    <span v-else class="badge badge-pill badge-secondary text-secondary">.</span>
                  </span>
                  <span v-else-if="item.indicator_type == 'GR'">
                    <span v-if="item.sensor_value == 0" class="badge badge-pill badge-success text-success">.</span>
                    <span v-else-if="item.sensor_value == 1" class="badge badge-pill badge-danger text-danger">.</span>
                    <span v-else class="badge badge-pill badge-secondary text-secondary">.</span>
                  </span>
                  <span v-else-if="item.indicator_type == 'RL'">
                    <span v-if="item.sensor_value == 0" class="badge badge badge-info text">R</span>
                    <span v-else-if="item.sensor_value == 1" class="badge badge badge-info text">L</span>
                    <span v-else class="badge badge badge-secondary text-secondary">.</span>
                  </span>
                  <span v-else>
                    v-else
                  </span>
                  {{item.sensor_name}}
                </span>
              </div>
            </div>
          </card>
          <card class="border">
            <h4>J02</h4>
            <div v-for="item in sensor_mapping_data" :key="item.id">
              <div v-if="item.sensor_junction == 'J02'">
                <span>
                  <span v-if="item.indicator_type == 'RG'">
                    <span v-if="item.sensor_value == 0" class="badge badge-pill badge-danger text-danger">.</span>
                    <span v-else-if="item.sensor_value == 1" class="badge badge-pill badge-success text-success">.</span>
                    <span v-else class="badge badge-pill badge-secondary text-secondary">.</span>
                  </span>
                  <span v-else-if="item.indicator_type == 'GR'">
                    <span v-if="item.sensor_value == 0" class="badge badge-pill badge-success text-success">.</span>
                    <span v-else-if="item.sensor_value == 1" class="badge badge-pill badge-danger text-danger">.</span>
                    <span v-else class="badge badge-pill badge-secondary text-secondary">.</span>
                  </span>
                  <span v-else-if="item.indicator_type == 'RL'">
                    <span v-if="item.sensor_value == 0" class="badge badge badge-info text">R</span>
                    <span v-else-if="item.sensor_value == 1" class="badge badge badge-info text">L</span>
                    <span v-else class="badge badge badge-secondary text-secondary">.</span>
                  </span>
                  <span v-else>
                    v-else
                  </span>
                  {{item.sensor_name}}
                </span>
              </div>
            </div>            
          </card>
          <card class="border" v-if="substation_data.substation_type == 2">
            <h4>J03</h4>
            <div v-for="item in sensor_mapping_data" :key="item.id">
              <div v-if="item.sensor_junction == 'J03'">
                <span>
                  <span v-if="item.indicator_type == 'RG'">
                    <span v-if="item.sensor_value == 0" class="badge badge-pill badge-danger text-danger">.</span>
                    <span v-else-if="item.sensor_value == 1" class="badge badge-pill badge-success text-success">.</span>
                    <span v-else class="badge badge-pill badge-secondary text-secondary">.</span>
                  </span>
                  <span v-else-if="item.indicator_type == 'GR'">
                    <span v-if="item.sensor_value == 0" class="badge badge-pill badge-success text-success">.</span>
                    <span v-else-if="item.sensor_value == 1" class="badge badge-pill badge-danger text-danger">.</span>
                    <span v-else class="badge badge-pill badge-secondary text-secondary">.</span>
                  </span>
                  <span v-else-if="item.indicator_type == 'RL'">
                    <span v-if="item.sensor_value == 0" class="badge badge badge-info text">R</span>
                    <span v-else-if="item.sensor_value == 1" class="badge badge badge-info text">L</span>
                    <span v-else class="badge badge badge-secondary text-secondary">.</span>
                  </span>
                  <span v-else>
                    v-else
                  </span>
                  {{item.sensor_name}}
                </span>
              </div>
            </div>
          </card>
          <card class="border">
            <h4>CCTV</h4>
            <div v-for="item in sensor_mapping_data" :key="item.id">
              <div v-if="item.sensor_junction == 'CCTV'">
                <span>
                  <span v-if="item.indicator_type == 'RG'">
                    <span v-if="item.sensor_value == 0" class="badge badge-pill badge-danger text-danger">.</span>
                    <span v-else-if="item.sensor_value == 1" class="badge badge-pill badge-success text-success">.</span>
                    <span v-else class="badge badge-pill badge-secondary text-secondary">.</span>
                  </span>
                  <span v-else-if="item.indicator_type == 'GR'">
                    <span v-if="item.sensor_value == 0" class="badge badge-pill badge-success text-success">.</span>
                    <span v-else-if="item.sensor_value == 1" class="badge badge-pill badge-danger text-danger">.</span>
                    <span v-else class="badge badge-pill badge-secondary text-secondary">.</span>
                  </span>
                  <span v-else-if="item.indicator_type == 'RL'">
                    <span v-if="item.sensor_value == 0" class="badge badge badge-info text">R</span>
                    <span v-else-if="item.sensor_value == 1" class="badge badge badge-info text">L</span>
                    <span v-else class="badge badge badge-secondary text-secondary">.</span>
                  </span>
                  <span v-else>
                    v-else
                  </span>
                  {{item.sensor_name}}
                </span>
              </div>
            </div>
          </card>
        </card>
      </div>

      <div class="col">
        <card class="border">
          <h4 style="text-align: center">SLD</h4>
          <!-- <card class="border"> -->
            <canvas
              id="myCanvas"
              ref = "refCanvas"
              width="599"
              height="757"
              style="border: 1px solid #000000">
            </canvas>
          </card>
        <!-- </card> -->
      </div>

      <div class="col">
        <card class="border">
          <h4 style="text-align: center">Measurements</h4>
          <div v-for="item in sensor_mapping_data" :key="item.id">
            <div v-if="item.sensor_junction == 'MEAS'">
              <div class="row">
                <div class="col-7 pr-0">{{ item.sensor_name }} :</div>
                <div class="col-3 border" style="text-align: center">{{ item.sensor_value }}</div>
                <div class="col-2">{{ item.sensor_unit }}</div>
              </div>
            </div>
          </div>
        </card>
        <base-button size="sm" type="primary" style="float:right" @click="downloadCsv()">Download CSV</base-button>
        <!-- <select v-model="selectedYear">
          <option value="">Select Year</option>
          <option v-for="year in yearOptions" :value="year">{{ year }}</option>
        </select>
        <select v-model="selectedMonth">
          <option value="">Select Month</option>
          <option v-for="month in monthOptions" :value="month.value">{{ month.label }}</option>
        </select> -->
        <input type="date" v-model="startDate">
        <input type="date" v-model="endDate">
      </div>
    </div>

    <!-- <div>substation_data: {{ substation_data }}</div> -->
    <!-- <div>substation_sensor_data: {{ substation_sensor_data }}</div> -->
    <!-- <div>sensor_mapping_data: {{ sensor_mapping_data }}</div> -->
    <!-- <div>responseData: {{ responseData }}</div> -->
  </div>
</template>

<script>
import api from "Api";
import moment from 'moment';

export default {
  data() {
    return {
      substation_id: null,
      substation_data: null,
      substation_sensor_data: null,
      sensor_mapping_data: null,
      datetime_refresh_data: null,
      datetime_data: null,
      responseData: null,
      tempSomething: "start || ",
      timer: null,
      refresh_timer: null,
      remaining_time: 30,
      imageDataURL: null,

      // selectedYear: '',   // Initialize selectedYear as an empty string
      // selectedMonth: '',  // Initialize selectedMonth as an empty string

      startDate: '',  // Initialize start date as an empty string
      endDate: '',    // Initialize end date as an empty string
    };
  },
  created() {
    this.substation_id = this.$route.params.id;
    this.getSubstationData();
    // this.getSensorData();
    this.getSensorMapping();
  },
  mounted() {
    this.$nextTick(function() {
      // Code that needs to run after the DOM has been updated goes here
      this.timer = window.setInterval(() => {
        this.getSensorData();
        this.remaining_time = 30;
      }, 30000);
      this.refresh_timer = window.setInterval(() => {
        this.remaining_time -= 1;
      }, 1000);
    });

    // this.$nextTick(function() {
    //   // More code that needs to run after the DOM has been updated goes here
    // });

    // // Generate options for years (e.g., from the current year to the past 5 years)
    // const currentYear = new Date().getFullYear();
    // const yearOptions = Array.from({ length: 5 }, (_, i) => (currentYear - i).toString());

    // // Generate options for months
    // const monthOptions = [
    //   { value: '01', label: 'January' },
    //   { value: '02', label: 'February' },
    //   { value: '03', label: 'March' },
    //   { value: '04', label: 'April' },
    //   { value: '05', label: 'May' },
    //   { value: '06', label: 'June' },
    //   { value: '07', label: 'July' },
    //   { value: '08', label: 'August' },
    //   { value: '09', label: 'September' },
    //   { value: '10', label: 'October' },
    //   { value: '11', label: 'November' },
    //   { value: '12', label: 'December' },
    // ];

    // // Set the generated options as data properties
    // this.yearOptions = yearOptions;
    // this.monthOptions = monthOptions;
  },
  methods: {
    getSubstationData() {
      api
        .get("substation/substations/" + this.substation_id)
        .then((response) => {
          this.substation_data = response.data;
        })
        .catch((error) => {});
    },
    getSensorData() {
      api
        .get("substation/sensordatas/" + this.substation_id)
        .then((response) => {
          this.substation_sensor_data = response.data;
          this.datetime_refresh_data = new Date();
          this.convertTimestamp();
          this.mapSensorData();
          this.removePreviousDrawings();
        })
        .catch((error) => {});
    },
    getSensorMapping() {
      api
        .get("sensor/sensors/")
        .then((response) => {
          this.sensor_mapping_data = response.data;
          this.mapSensorData();
          this.loadBackground();
        })
        .catch((error) => {});
      this.getSensorData();
    },
    mapSensorData() {
      if ((this.substation_sensor_data != null) && (this.sensor_mapping_data != null)) {
        Object.keys(this.substation_sensor_data[0]).forEach((key) => {
          if (key.startsWith("_")) {
            this.sensor_mapping_data.forEach((e) => {
              let sensor_id_rename = "_" + e['sensor_id'];
              if (key == sensor_id_rename) {
                e['sensor_value'] = this.substation_sensor_data[0][key];
              }
            });
          }
        });        
      }      
    },
    convertTimestamp() {
      this.datetime_data = new Date(this.substation_sensor_data[0].timestamp * 1000);
      this.datetime_data.setHours(this.datetime_data.getHours() - 8);
    },
    loadBackground() {
      console.log('inside loadBackground');
      this.tempSomething += "entered loadBackground || ";
      var c = document.getElementById("myCanvas");
      console.log('get c');
      console.log(c);
      var ctx = c.getContext("2d");
      var img = new Image();
      if (this.substation_data.substation_type == 2) {
        img.src = "img/background_new_j03.png";
      } else {
        img.src = "img/background_new.png";
      }
      let imgWidth = 0;
      let imgHeight = 0;
      img.onload = function () {
        this.tempSomething += "entered onload || ";
        imgWidth = this.width;
        imgHeight = this.height;
        c.width = imgWidth;
        c.height = imgHeight;
        ctx.drawImage(img, 0, 0);
      };

      if (this.substation_data.substation_type == 2) {
        this.updateJ02TransformerJ03();
        this.updateJ03TransformerJ03();
      } else {
        this.updateJ02Transformer();
      }

      this.saveBackgroundWithTrafo();
    },
    updateCanvas() {
      this.tempSomething += "entered updateCanvas || ";

      this.updateJ01CB();
      this.updateJ01Remote();
      this.updateJ02CB();
      this.updateJ02Remote();
    },
    updateCanvasJ03() {
      this.tempSomething += "entered updateCanvasJ03 || ";

      this.updateJ01CBJ03();
      this.updateJ01RemoteJ03();
      this.updateJ02CBJ03();      
      this.updateJ02RemoteJ03();
      this.updateJ03CBJ03();
      this.updateJ03RemoteJ03();
    },
    updateJ02Transformer() {
      this.tempSomething += "entered updateTransformer || ";
      var c = document.getElementById("myCanvas");
      var ctx = c.getContext("2d");
      var img = new Image();
      if (this.substation_data.transformer_type == 1) {
          // 1 Delta 1 Star
          img.src = "img/1_delta_1_star.png";
        } else if (this.substation_data.transformer_type == 2) {
          // 1 Delta 2 Star
          img.src = "img/1_delta_2_star.png";
        } else {
          img.src = "img/no_data.png";
        }
        img.onload = function () {
          ctx.drawImage(img, 142, 675, 80, 80);
        };
    },
    updateJ02TransformerJ03() {
      this.tempSomething += "entered updateTransformerJ03 || ";
      var c = document.getElementById("myCanvas");
      var ctx = c.getContext("2d");
      var img = new Image();
      if (this.substation_data.transformer_type == 1) {
          // 1 Delta 1 Star
          img.src = "img/1_delta_1_star.png";
        } else if (this.substation_data.transformer_type == 2) {
          // 1 Delta 2 Star
          img.src = "img/1_delta_2_star.png";
        } else {
          img.src = "img/no_data.png";
        }
        img.onload = function () {
          ctx.drawImage(img, 342, 675, 80, 80);
        };
    },
    updateJ03TransformerJ03() {
      this.tempSomething += "entered updateJ03TransformerJ03 || ";
      var c = document.getElementById("myCanvas");
      var ctx = c.getContext("2d");
      var img = new Image();
      // if (this.substation_data.transformer_type == 1) {
      //     // 1 Delta 1 Star
      //     img.src = "img/1_delta_1_star.png";
      //   } else if (this.substation_data.transformer_type == 2) {
      //     // 1 Delta 2 Star
      //     img.src = "img/1_delta_2_star.png";
      //   } else {
      //     img.src = "img/no_data.png";
      //   }
        img.src = "img/no_data.png";
        img.onload = function () {
          ctx.drawImage(img, 80, 675, 80, 80);
        };
    },
    saveBackgroundWithTrafo(){
      var c = document.getElementById("myCanvas");
      var ctx = c.getContext("2d");
      this.imageDataURL = ctx.toDataURL();
    },
    removePreviousDrawings() {
      this.tempSomething += "entered removePreviousDrawings || ";
      var c = document.getElementById("myCanvas");
      var ctx = c.getContext("2d");
      var img = new Image();
      img.src = this.imageDataURL;
      img.onload = function () {
        ctx.drawImage(img, 0, 0);
      };
      
      if (this.substation_data.substation_type == 2) {
        this.updateCanvasJ03();
      } else {
        this.updateCanvas();
      }
    },
    updateJ01CB() {
      this.tempSomething += "entered updateJ01CB || ";
        var c = document.getElementById("myCanvas");
        var ctx = c.getContext("2d");
        var img = new Image();
        if (this.substation_sensor_data[0]['_115001'] == 0) {
            img.src = "img/open_red.png";
        } else if (this.substation_sensor_data[0]['_115001'] == 1) {
            img.src = "img/closed_red.png";
        } else {
            img.src = "img/no_data.png";
        }
        img.onload = function () {
            ctx.drawImage(img, 331, 122, 60, 60);
        };
    },
    updateJ02CB() {
        var c = document.getElementById("myCanvas");
        var ctx = c.getContext("2d");
        var img = new Image();
        if (this.substation_sensor_data[0]['_115003'] == 0) {
            img.src = "img/open_red.png";
        } else if (this.substation_sensor_data[0]['_115003'] == 1) {
            img.src = "img/closed_red.png";
        } else {
            img.src = "img/no_data.png";
        }
        img.onload = function () {
            ctx.drawImage(img, 152, 441, 60, 60);
        };
    },
    updateJ01Remote() {
      this.tempSomething += "entered updateJ01Remote || ";
        var c = document.getElementById("myCanvas");
        var ctx = c.getContext("2d");
        ctx.textAlign = "center";
        ctx.font = "30px Arial";
        ctx.fillStyle = "#0CADFE";
        ctx.fillRect(420, 122, 60, 60);
        ctx.fillStyle = "black";
        if (this.substation_sensor_data[0]['_115109'] == 0) {
            ctx.fillText("R", 449, 162); 
        } else if (this.substation_sensor_data[0]['_115109'] == 1) {
            ctx.fillText("L", 449, 162); 
        } else {
            ctx.fillText("N/A", 449, 162); 
        }
    },
    updateJ02Remote() {
        var c = document.getElementById("myCanvas");
        var ctx = c.getContext("2d");
        ctx.textAlign = "center";
        ctx.font = "30px Arial";
        ctx.fillStyle = "#0CADFE";
        ctx.fillRect(241, 441, 60, 60);
        ctx.fillStyle = "black";
        if (this.substation_sensor_data[0]['_115120'] == 0) {
            ctx.fillText("R", 271, 480); 
        } else if (this.substation_sensor_data[0]['_115120'] == 1) {
            ctx.fillText("L", 271, 480); 
        } else {
            ctx.fillText("N/A", 271, 480); 
        }
    },
    updateJ01CBJ03() {
      this.tempSomething += "entered updateJ01CB || ";
        var c = document.getElementById("myCanvas");
        var ctx = c.getContext("2d");
        var img = new Image();
        if (this.substation_sensor_data[0]['_115001'] == 0) {
            img.src = "img/open_red.png";
        } else if (this.substation_sensor_data[0]['_115001'] == 1) {
            img.src = "img/closed_red.png";
        } else {
            img.src = "img/no_data.png";
        }
        img.onload = function () {
            ctx.drawImage(img, 531, 122, 60, 60);
        };
    },
    updateJ02CBJ03() {
        var c = document.getElementById("myCanvas");
        var ctx = c.getContext("2d");
        var img = new Image();
        if (this.substation_sensor_data[0]['_115003'] == 0) {
            img.src = "img/open_red.png";
        } else if (this.substation_sensor_data[0]['_115003'] == 1) {
            img.src = "img/closed_red.png";
        } else {
            img.src = "img/no_data.png";
        }
        img.onload = function () {
            ctx.drawImage(img, 352, 441, 60, 60);
        };
    },
    updateJ03CBJ03() {
        var c = document.getElementById("myCanvas");
        var ctx = c.getContext("2d");
        var img = new Image();
        if (this.substation_sensor_data[0]['_115005'] == 0) {
            img.src = "img/open_red.png";
        } else if (this.substation_sensor_data[0]['_115005'] == 1) {
            img.src = "img/closed_red.png";
        } else {
            img.src = "img/no_data.png";
        }
        img.onload = function () {
            ctx.drawImage(img, 90, 441, 60, 60);
        };
    },
    updateJ01RemoteJ03() {
      this.tempSomething += "entered updateJ01Remote || ";
        var c = document.getElementById("myCanvas");
        var ctx = c.getContext("2d");
        ctx.textAlign = "center";
        ctx.font = "30px Arial";
        ctx.fillStyle = "#0CADFE";
        ctx.fillRect(620, 122, 60, 60);
        ctx.fillStyle = "black";
        if (this.substation_sensor_data[0]['_115109'] == 0) {
            ctx.fillText("R", 649, 162); 
        } else if (this.substation_sensor_data[0]['_115109'] == 1) {
            ctx.fillText("L", 649, 162); 
        } else {
            ctx.fillText("N/A", 649, 162); 
        }
    },
    updateJ02RemoteJ03() {
        var c = document.getElementById("myCanvas");
        var ctx = c.getContext("2d");
        ctx.textAlign = "center";
        ctx.font = "30px Arial";
        ctx.fillStyle = "#0CADFE";
        ctx.fillRect(441, 441, 60, 60);
        ctx.fillStyle = "black";
        if (this.substation_sensor_data[0]['_115120'] == 0) {
            ctx.fillText("R", 471, 480); 
        } else if (this.substation_sensor_data[0]['_115120'] == 1) {
            ctx.fillText("L", 471, 480); 
        } else {
            ctx.fillText("N/A", 471, 480); 
        }
    },
    updateJ03RemoteJ03() {
        var c = document.getElementById("myCanvas");
        var ctx = c.getContext("2d");
        ctx.textAlign = "center";
        ctx.font = "30px Arial";
        ctx.fillStyle = "#0CADFE";
        ctx.fillRect(179, 441, 60, 60);
        ctx.fillStyle = "black";
        if (this.substation_sensor_data[0]['_115135'] == 0) {
            ctx.fillText("R", 209, 480); 
        } else if (this.substation_sensor_data[0]['_115135'] == 1) {
            ctx.fillText("L", 209, 480); 
        } else {
            ctx.fillText("N/A", 209, 480); 
        }
    },
    getSomeMethodsJ03() {},
    // getSubstationData() {
    //   api
    //     .get("substation/substations/" + this.substation_id)
    //     .then((response) => {
    //       this.substation_data = response.data;
    //     })
    //     .catch((error) => {});
    // },
    downloadCsv() {
      // const { selectedYear, selectedMonth } = this;

      // if (!selectedYear || !selectedMonth) {
      //   // Ensure both year and month are selected
      //   window.alert("Please select both year and month.");
      //   return;
      // }

      const { startDate, endDate } = this;

      if (!startDate || !endDate) {
        // Ensure both start date and end date are selected
        window.alert("Please select both start date and end date.");
        return;
      }

      // Convert the selected date strings to timestamps (in seconds)
      const startTimestamp = new Date(startDate).getTime() / 1000;
      const endTimestamp = new Date(endDate).getTime() / 1000;

      if (startTimestamp >= endTimestamp) {
        // Ensure that the start date is before the end date
        window.alert("Start date must be before end date.");
        return;
      }

      // Calculate the number of days between start and end dates
      const daysBetween = (endTimestamp - startTimestamp) / (24 * 60 * 60);

      if (daysBetween > 14) {
        // Ensure that the date range is not more than 15 days
        window.alert("Date range cannot be more than 15 days apart.");
        return;
      }

      const periodSelection = {
        start_date: startDate,
        end_date: endDate
      };

      api
        .post('substation/download_csv/' + this.substation_id, periodSelection, { responseType: 'blob' })
        .then ( response => {
          if (response.status === 200) {
            // // Get the current date in yyyy_mm_dd format
            // const currentDate = new Date().toISOString().slice(0, 10).replace(/-/g, "_");

            // // Replace 'safety_observation_report.xlsx' with the dynamic filename
            // const filename = `${currentDate}_substation_${this.substation_id}.csv`;
            // this.generate_report_response = response.data;

            const startDateFormatted = startDate.replace(/-/g, '');
            const endDateFormatted = endDate.replace(/-/g, '');

            const filename = `${startDateFormatted}_${endDateFormatted}_substation_${this.substation_id}.csv`;
            
            // create a temporary URL to download the blob response
            const url = window.URL.createObjectURL(new Blob([response.data]));
            
            // create a link element and click it to download the file
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            // window.alert(error.response.data.error);
            window.alert("No data available for selected period.");
          } else {
            window.alert("An error has occured.");
          } 
        });
    },
  },
  filters: {
    formatDate: (value) => {
      if (value) {
        return moment(String(value)).format('YYYY-MM-DD HH:mm:ss')
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
    clearInterval(this.refresh_timer);
  }
};
</script>

<style>
</style>