<template>
  <div>
    <h1>AMK Industrial Park II</h1>
    <div>display loaded text {{ incoming_text }}</div>
    <div>tempResponse {{ tempResponse }}</div>
    <div>tempWhere {{ tempWhere }}</div>
    <div class="row">
      <div class="col-3">
        TRF_J01
        <div class="row">
          <div class="col-12">
            <card
              ><div class="card-title">INC_J02 STATUS</div>
              <div
                v-for="item in inc_j02_status"
                :key="inc_j02_status.name"
                style="color: white"
              >
                <img v-if="item.status == 0" src="img/grey.png" height="50" />
                <img v-else src="img/green.png" height="50" />
                {{ item.name }}
              </div>
            </card>
          </div>
          <div class="col-12">
            <card
              ><div class="card-title">TXR_J01 STATUS</div>
              <div
                v-for="item in txr_j01_status"
                :key="txr_j01_status.name"
                style="color: white"
              >
                <img v-if="item.status == 0" src="img/grey.png" height="50" />
                <img v-else src="img/green.png" height="50" />
                {{ item.name }}
              </div>
            </card>
          </div>
        </div>
      </div>
      <div class="col-6">
        22kV GENERATING SWBD<card
          ><div>
            <canvas
              id="myCanvas"
              width="600"
              height="400"
              style="border: 1px solid #000000"
            ></canvas>
          </div>
          <div>
            <button type="button" @click="updateTopLeft">
              Toggle Top Left
            </button>
            <button type="button" @click="updateBottomLeft">
              Toggle Bottom Left
            </button>
            <button type="button" @click="updateBottomRight">
              Toggle Bottom Right
            </button>
          </div></card
        >
      </div>
      <div class="col-3">
        INC_J02<card><div class="card-title">Measurements</div></card>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  components: {},
  data() {
    return {
      topLeftStatus: false, // closed = true, open = false, default = open
      bottomLeftStatus: false,
      bottomRightStatus: false,
      // trf_j01: {
      //   inc_j02_status: {
      //     sf6_alarm: false,
      //     battery_charger_alarm: false,
      //     intertrip_from_sppg_feeder: false,
      //   },
      //   txr_j01_status: {},
      // },
      inc_j02_status: [],
      txr_j01_status: [],
      incoming_text: null,
      tempResponse: null,
      tempWhere: "",
    };
  },
  created() {
    this.tempWhere = this.tempWhere.concat("Before API Call");
    // axios
    //   .get("http://localhost:8000/substation/substations/")
    //   .then((response) => {
    //     if (response.status === 200) {
    //       this.tempWhere = this.tempWhere.concat("API Call Status 200");
    //       this.tempResponse = response.data;
    //     }
    //   })
    //   .catch((error) => {
    //     this.tempWhere = this.tempWhere.concat("API Call Error");
    //     console.log(error.response.status);
    //     this.errorFunction(error, "Substation");
    //   });
    axios.get("http://localhost:8000/substation/substations/").then((response) => {
      this.tempResponse = response.data;
    });
    this.tempWhere = this.tempWhere.concat("After API Call");
    this.loadData();
  },
  mounted() {
    this.$nextTick(() => {
      this.loadBackground();
      this.loadImages();
    });
  },
  methods: {
    loadData() {
      fetch("json-files/inc_j02_status.json")
        .then((response) => response.json())
        .then((json) => (this.inc_j02_status = json));
      fetch("json-files/txr_j01_status.json")
        .then((response) => response.json())
        .then((json) => (this.txr_j01_status = json));
      fetch("txt-files/sample.txt")
        .then((response) => response.text())
        .then((text) => (this.incoming_text = text));
    },
    loadBackground() {
      var c = document.getElementById("myCanvas");
      var ctx = c.getContext("2d");
      var img = new Image();
      img.src = "img/sample.png";
      let imgWidth = 0;
      let imgHeight = 0;
      img.onload = function () {
        imgWidth = this.width;
        imgHeight = this.height;
        c.width = imgWidth;
        c.height = imgHeight;
        ctx.drawImage(img, 0, 0);
      };
    },
    loadImages() {
      var c = document.getElementById("myCanvas");
      var ctx = c.getContext("2d");
      var img = new Image();
      img.src = "img/open.png";
      img.onload = function () {
        // TL 238,233 BR 289,281
        ctx.drawImage(img, 238, 233, 51, 48);
        // TL 291,414 BR 343,461
        ctx.drawImage(img, 291, 414, 52, 47);
        // TL 464,411 BR 515,461
        ctx.drawImage(img, 464, 411, 51, 50);
      };
    },
    updateTopLeft() {
      this.topLeftStatus = !this.topLeftStatus;

      var c = document.getElementById("myCanvas");
      var ctx = c.getContext("2d");
      var img = new Image();

      if (this.topLeftStatus) {
        img.src = "img/closed.png";
      } else {
        img.src = "img/open.png";
      }

      img.onload = function () {
        // TL 238,233 BR 289,281
        ctx.drawImage(img, 238, 233, 51, 48);
      };
    },
    updateBottomLeft() {
      this.bottomLeftStatus = !this.bottomLeftStatus;

      var c = document.getElementById("myCanvas");
      var ctx = c.getContext("2d");
      var img = new Image();

      if (this.bottomLeftStatus) {
        img.src = "img/closed.png";
      } else {
        img.src = "img/open.png";
      }

      img.onload = function () {
        // TL 291,414 BR 343,461
        ctx.drawImage(img, 291, 414, 52, 47);
      };
    },
    updateBottomRight() {
      this.bottomRightStatus = !this.bottomRightStatus;

      var c = document.getElementById("myCanvas");
      var ctx = c.getContext("2d");
      var img = new Image();

      if (this.bottomRightStatus) {
        img.src = "img/closed.png";
      } else {
        img.src = "img/open.png";
      }

      img.onload = function () {
        // TL 464,411 BR 515,461
        ctx.drawImage(img, 464, 411, 51, 50);
      };
    },
  },
};
</script>
<style>
</style>
