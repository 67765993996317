<template>
  <div>
    <div>
      <canvas id="myCanvas" width="600" height="400" style="border:1px solid #000000;"></canvas>
    </div>
    <div>
      <button type="button" @click="updateTopLeft">Toggle Top Left</button>
      <button type="button" @click="updateBottomLeft">Toggle Bottom Left</button>
      <button type="button" @click="updateBottomRight">Toggle Bottom Right</button>
    </div>
  </div>
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
      topLeftStatus: false, // closed = true, open = false, default = open
      bottomLeftStatus: false,
      bottomRightStatus: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.loadBackground();
      this.loadImages();
    });
  },
  methods: {
    loadBackground() {
      var c = document.getElementById("myCanvas");
      var ctx = c.getContext("2d");
      var img = new Image();
      img.src = 'img/sample.png';
      let imgWidth = 0;
      let imgHeight = 0;
      img.onload = function () {
        imgWidth = this.width;
        imgHeight = this.height;
        c.width = imgWidth;
        c.height = imgHeight;
        ctx.drawImage(img, 0, 0);
      };
    },
    loadImages() {
      var c = document.getElementById("myCanvas");
      var ctx = c.getContext("2d");
      var img = new Image();
      img.src = 'img/open.png';
      img.onload = function () {
        // TL 238,233 BR 289,281
        ctx.drawImage(img, 238, 233, 51, 48);
        // TL 291,414 BR 343,461
        ctx.drawImage(img, 291, 414, 52, 47);
        // TL 464,411 BR 515,461
        ctx.drawImage(img, 464, 411, 51, 50);
      };
    },
    updateTopLeft() {
      this.topLeftStatus = !this.topLeftStatus;

      var c = document.getElementById("myCanvas");
      var ctx = c.getContext("2d");
      var img = new Image();

      if (this.topLeftStatus) {
        img.src = 'img/closed.png';
      }
      else {
        img.src = 'img/open.png';
      }

      img.onload = function () {
        // TL 238,233 BR 289,281
        ctx.drawImage(img, 238, 233, 51, 48);
      };
    },
    updateBottomLeft() {
      this.bottomLeftStatus = !this.bottomLeftStatus;
      
      var c = document.getElementById("myCanvas");
      var ctx = c.getContext("2d");
      var img = new Image();

      if (this.bottomLeftStatus) {
        img.src = 'img/closed.png';
      }
      else {
        img.src = 'img/open.png';
      }

      img.onload = function () {
        // TL 291,414 BR 343,461
        ctx.drawImage(img, 291, 414, 52, 47);
      };
    },
    updateBottomRight() {
      this.bottomRightStatus = !this.bottomRightStatus;
      
      var c = document.getElementById("myCanvas");
      var ctx = c.getContext("2d");
      var img = new Image();

      if (this.bottomRightStatus) {
        img.src = 'img/closed.png';
      }
      else {
        img.src = 'img/open.png';
      }

      img.onload = function () {
        // TL 464,411 BR 515,461
        ctx.drawImage(img, 464, 411, 51, 50);
      };
    },
  },
};
</script>
<style>
</style>
